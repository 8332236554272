import {FC} from "react";
import NextLink from "next/link";
import {Link} from "@mui/material";

interface Props {
    facilityId: number
}

const FacilityLink: FC<Props> = ({ children, facilityId }) => {
    return (
        // <NextLink
        //     passHref
        // >
        // </NextLink>
        <Link
            color={'textPrimary'}
            href={`/facilities/${facilityId}`}
            target="_blank"
        >
            { children }
        </Link>
    )
}

export default FacilityLink
