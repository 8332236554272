import {Button} from "@mui/material";
import NextLink from "next/link";
import {RiDeleteBin7Line, RiEditLine} from "react-icons/ri";
import {Box} from "@mui/system";
import React from "react";

interface Props {
    id: number
    isEnabled: boolean
    onActivate: (id: number) => void
    onRemove: (id: number) => void
}

const FacilityCardActions = ({ onActivate, onRemove, id, isEnabled }: Props) => {
    return (
        <Box
            display={'flex'}
            alignItems={'flex-end'}
            gap={'0.8em'}
        >
            <Button
                variant={isEnabled ? 'outlined' : 'contained'}
                onClick={(e) => {
                    onActivate(id)
                    e.stopPropagation()
                }}
            >
                { isEnabled ? 'Деактивировать' : 'Активировать' }
            </Button>
            <NextLink href={'/facilities/' + id + '/edit'}>
                <Button variant={'outlined'} onClick={(e) => e.stopPropagation()}>
                    <RiEditLine size={24}/>
                </Button>
            </NextLink>
            <Button
                variant={'outlined'}
                onClick={(e) => {
                    onRemove(id)
                    e.stopPropagation()
                }}
            >
                <RiDeleteBin7Line size={24}/>
            </Button>
        </Box>
    )
}

export default FacilityCardActions
