import {FaMapMarkerAlt} from "react-icons/fa";
import {RiApps2Fill} from "react-icons/ri";
import {Box} from "@mui/system";
import React from "react";
import usePrice from "../../hooks/usePrice";
import {FacilityType} from "@app/types/facility";

interface Props {
    address: string
    pricePerMeter: number
    count: string
    square: string
    type: FacilityType
}

const FacilityCardInfo = ({pricePerMeter, address, count, square, type}: Props) => {
    const formattedPricePerMeter = usePrice(pricePerMeter)

    return (
        <Box>
            <p style={{display: 'flex', alignItems: 'center', margin: 0}}>
                <FaMapMarkerAlt style={{marginRight: '0.5em'}}/>
                <span style={{color: '#858585'}}>{address}</span>
            </p>
            <p style={{display: 'flex', alignItems: 'center', margin: 0}}>
                <RiApps2Fill style={{marginRight: '0.5em'}}/>
                <span
                    style={{
                        color: '#858585',
                        marginTop: '-0.2em'
                    }}
                >
                    {count}, {square} м<sup>2</sup>, {type !== FacilityType.FLAT && 'от '}{formattedPricePerMeter} за м<sup>2</sup>
                </span>
            </p>
        </Box>
    )
}

export default FacilityCardInfo
