import {Facility} from "@app/types/facility";
import FacilityCard from "@components/facilities/facility-card";
import React, {ChangeEvent, MouseEvent, useRef} from "react";
import {useAppContext} from "@app/app-context";
import {useRouter} from "next/router";
import {toast} from "react-hot-toast";
import {TablePagination} from "@mui/material";

interface Props {
    facilities: Facility[]
    onUpdateList: (list: Facility[]) => void
    total: number
    limit: number
    page: number
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FacilityList = ({facilities, onUpdateList, limit, page, total, onPageChange, onRowsPerPageChange}: Props) => {
    const {api} = useAppContext()
    const router = useRouter()
    const ref = useRef<HTMLDivElement>()

    const activate = (id: number) => {
        const facility = facilities.find(f => f.id === id)
        api.facility.update(id, {isEnabled: !facility.isEnabled})
            .then(res => {
                onUpdateList(facilities.map(f => {
                    if (f.id !== id) return f
                    else return res
                }))
                toast.success(`Объект ${facility.isEnabled ? 'деактивирован' : 'активирован'}`)
            })
    }

    const remove = (id: number) => {
        api.facility.delete(id)
            .then(() => {
                onUpdateList(facilities.filter(f => f.id !== id))
                toast.success('Объект удален')
            })
    }

    const onChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        // console.log(newPage)
        if (onPageChange) {
            onPageChange(event, newPage)
        }

        ref.current?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <div ref={ref}>
            {
                facilities.map((facility, index) => (
                    <FacilityCard
                        key={facility.id}
                        photo={facility.photoNames.length > 0 ? api.getFilePath(facility.photoNames[0]) : '/static/facility/template.png'}
                        facility={facility}
                        onClick={(id: number) => router.push(`/facilities/${id}`)}
                        onActivate={activate}
                        onRemove={remove}
                    />
                ))
            }
            <TablePagination
                component="div"
                count={total}
                onPageChange={onChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </div>
    )
}

export default FacilityList
