import Badge from "@components/Badge/Badge";
import {Box} from "@mui/system";
import React from "react";
import {Facility} from "@app/types/facility";
import SalesTooltip from "@components/facility-card/sales-tooltip";
import ComissionsTooltip from "@components/facility-card/comissions-tooltip";

interface Props {
    facility: Facility
}

const FacilityCardBadges = ({ facility }: Props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: '0.5em',
                mt: 2,
                mb: 2
            }}
        >
            { facility.isDelivered && <Badge type={'delivery'}>Сдан</Badge> }
            { facility.deliveryDate && !facility.isDelivered && <Badge type={'delivery'}>{new Date(facility.deliveryDate).getFullYear()}</Badge> }
            { facility.sales.length > 0 && <SalesTooltip sales={ facility.sales.map((s) => s.replaceAll('##', ',')) }></SalesTooltip> }
            { facility.commissions.length > 0 && <ComissionsTooltip comissions={facility.commissions.map(c => c.replaceAll('##', ','))} /> }
            { facility.registrations.map((reg, i) => (
                <Badge key={i} type={'registration'}>{reg}</Badge>
            )) }
        </Box>
    )
}

export default FacilityCardBadges
