import {Box} from "@mui/system";
import {Divider, Grid, Typography} from "@mui/material";
import React, {useMemo} from "react";
import {Facility, FacilityType, RoomCount} from "@app/types/facility";
import FacilityLink from "@components/facilities/facility-link";
import FacilityCardActions from "@components/facilities/FacilityCardActions";
import FacilityCardPrice from "@components/facilities/FacilityCardPrice";
import FacilityCardInfo from "@components/facility-card/FacilityCardInfo";
import FacilityCardBadges from "@components/facility-card/FacilityCardBadges";
import FacilityCardImage from "@components/facility-card/FacilityCardImage";
import FacilityCardPerson from "@components/facility-card/FacilityCardPerson";

interface Props {
    photo: string
    facility: Facility
    onClick: (id: number) => void
    onActivate: (id: number) => void
    onRemove: (id: number) => void
}

const FacilityCard = ({photo, facility, onActivate, onRemove}: Props) => {
    const count = useMemo<string>(() => {
        if (facility.facilityType === FacilityType.COTTAGE) return `${facility.cottageCount} коттеджей`
        else if (facility.facilityType === FacilityType.NEW) return `${facility.flatsCount} квартир`
        else if (facility.facilityType === FacilityType.FLAT) return facility.roomCount === RoomCount.STUDIO ? RoomCount.STUDIO : `${facility.roomCount} комнат`
    }, [facility.facilityType, facility.roomCount, facility.flatsCount, facility.cottageCount])

    const square = useMemo<string>(() => {
        if (facility.facilityType === FacilityType.FLAT) return facility.square.toString()
        else return `${facility.minSquare}-${facility.maxSquare}`
    }, [facility])

    return (
        <>
            <Box>
                <Grid
                    container
                    spacing={2}
                >
                    <FacilityCardImage id={facility.id} photo={photo}/>
                    <Grid
                        item
                        md={8}
                        xs={12}
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box>
                            <Box>
                                <FacilityLink facilityId={facility.id}>
                                    <Typography variant={'h5'}>{facility.name}</Typography>
                                </FacilityLink>
                            </Box>
                            <FacilityCardBadges facility={facility}/>
                            <FacilityCardInfo
                                address={facility.address}
                                pricePerMeter={facility.pricePerMeter}
                                count={count}
                                square={square}
                                type={facility.facilityType}
                            />
                            <FacilityCardPerson
                                isMock={facility.developerIsMock}
                                type={facility.facilityType}
                                developer={facility.facilityType !== FacilityType.FLAT ? {
                                    id: facility.developerId,
                                    name: facility.developerName
                                } : undefined}
                                owner={facility.facilityType === FacilityType.FLAT ? {
                                    id: facility.ownerId,
                                    name: facility.ownerName
                                } : undefined}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <FacilityCardPrice
                                type={facility.facilityType}
                                price={facility.facilityType === FacilityType.FLAT ? facility.price : facility.minPrice}
                            />
                            <FacilityCardActions
                                id={facility.id}
                                isEnabled={facility.isEnabled}
                                onActivate={onActivate}
                                onRemove={onRemove}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{mt: 2, mb: 2}}/>
        </>
    )
}

export default FacilityCard
