import {Link, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React, {useMemo} from "react";
import {FacilityType} from "@app/types/facility";
import NextLink from "next/link";

type LinkData = {
    id: number
    name: string
}

interface Props {
    isMock?: boolean
    owner?: LinkData
    developer?: LinkData
    type: FacilityType
}

const FacilityCardPerson = ({developer, isMock, owner, type}: Props) => {
    const link = useMemo(() => {
        if (owner?.id && type === FacilityType.FLAT) return `/owners/${owner.id}`
        else if (developer?.id && type !== FacilityType.FLAT) return `/developers/${developer.id}`
        else return undefined
    }, [developer, owner])

    if (!link || isMock) return null

    return (
        <Box sx={{mt: 2, cursor: 'pointer'}}>
            <NextLink href={link}>
                <Link>
                    <Typography>{developer?.name || owner?.name}</Typography>
                </Link>
            </NextLink>
        </Box>
    )
}

export default FacilityCardPerson
