import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import usePrice from "../../hooks/usePrice";
import {FacilityType} from "@app/types/facility";

interface Props {
    price: number
    type: FacilityType
}

const FacilityCardPrice = ({price, type}: Props) => {
    const formattedPrice = usePrice(price)

    return (
        <Box>
            <Typography
                style={{
                    color: '#1F64E5',
                    fontWeight: 500,
                    fontSize: '1.2em',
                    paddingTop: 0
                }}
            >
                {type !== FacilityType.FLAT && 'от'} {formattedPrice} руб.
            </Typography>
        </Box>
    )
}

export default FacilityCardPrice
