import {styled} from "@mui/material/styles";
import {Box, Tooltip, Button, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import Badge from "@components/Badge/Badge";

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip placement="top" {...props} classes={{popper: className}} arrow/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

interface Props {
    comissions: string[]
}

const ComissionsTooltip = ({comissions}: Props) => {
    return (
        <HtmlTooltip
            title={
                comissions.map((comission, index) => (
                    <Box key={index}>
                        <Typography color="inherit" fontWeight={600}>Комиссия</Typography>
                        <Typography>{comission}</Typography>
                    </Box>
                ))
            }
        >
            <Button style={{"padding": '0'}} component="div">
                <Badge type={'commission'}>Комиссия</Badge>
            </Button>
        </HtmlTooltip>
    )
}

export default ComissionsTooltip
