import {FC} from "react";
// @ts-ignore
import styles from './Badge.module.scss'
import clsx from "clsx";

type BadgeType = 'commission' | 'sale' | 'delivery' | 'registration'

interface Props {
    type: BadgeType
}

const Badge: FC<Props> = ({ type, children }) => {
    const classes = clsx(
        styles.badge,
        styles[type]
    )

    return (
        <div className={classes}>
            { children }
        </div>
    )
}

export default Badge
