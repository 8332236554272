import { useEffect, useState } from "react";
import formatPrice from "@utils/format-site";

export default function usePrice(price: number): string {
    const [formattedPrice, setFormattedPrice] = useState<string>();

    useEffect(() => {
        if (price === null) return;
        setFormattedPrice(formatPrice(price));
    }, [price]);

    return formattedPrice;
}
