import FacilityLink from "@components/facilities/facility-link";
import {Grid} from "@mui/material";
import React from "react";

interface Props {
    id: number
    photo: string
}

const FacilityCardImage = ({ photo, id }: Props) => {
    return (
        <Grid
            item
            md={4}
            xs={12}
        >
            <FacilityLink facilityId={id}>
                <img
                    src={photo}
                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                />
            </FacilityLink>
        </Grid>
    )
}

export default FacilityCardImage
